<template>
  <span class="relative-container">
    <v-menu
      id="idForReplay"
      class="pa-0 ma-0"
      style="
        max-width: 384px !important;
        min-width: 384px !important;
        max-height: 485px !important;
        min-height: 485px !important;
      "
      v-model="show"
      attach
      bottom
      left
      offset-y
      :close-on-content-click="false"
      :close-on-click="false"
    >
      <template v-slot:activator="{ on, attrs, value }">
        <v-btn
          :class="selectedLength > 0 && boolForTags ? 'secundary-default2' : 'secundary-default'"
          :style="
            value
              ? 'background-color: #2a1c4f !important;color:white !important; box-shadow: none !important; transition: none !important;-webkit-box-shadow: none'
              : 'box-shadow:none !important; transition: none !important;-webkit-box-shadow: none;'
          "
          :disabled="disabledOrNot"
          :ripple="false"
          v-bind="attrs"
          v-on="on"
          content-class="elevation-0"
          icon
          @click.prevent="buildStyle"
        >
          <span class="material-icons-outlined pr-2"> filter_alt </span>
          {{ selectedLength > 0 && boolForTags ? `Filtro (${selectedLength})` : 'Filtro' }}
        </v-btn>
      </template>
      <v-card class="mb-0 pa-0">
        <v-card-title class="pa-4" style="max-height: 64px !important">
          <span
            id="titleFiltro"
            class="text-h5 pa-0 ma-0"
            :style="'color: #182026;font-weight:700;line-height:30px!important;font-size:20px!important;height:30px!important'"
            >Filtro</span
          >
          <v-spacer class="pa-0"></v-spacer>
          <v-btn
            id="closeButtonFromUp"
            @click="closeFilter()"
            icon
            dark
            :ripple="false"
            small
            class="pa-0 ma-0"
            style="width: 32px !important; height: 32px !important"
          >
            <span id="iconClose" class="material-symbols-outlined"> close </span>
          </v-btn>
        </v-card-title>

        <v-list
          width="384"
          style="max-height: 421x !important; border-top: 1px solid var(--v-secondary-lighten5) !important"
          class="ma-0 pa-0"
        >
          <v-list-item class="pt-4 not">
            <v-autocomplete
              id="vAutoCompleteAgNoc"
              v-model="localFilter.nociveAgent"
              :items="countNociveAgentsForFilter"
              append-icon="mdi-chevron-down"
              item-text="name"
              item-value="code"
              dense
              outlined
              label="Agente nocivo"
              clearable
              hide-details
              placeholder="Selecione uma opção"
              @input="(value) => changeNociveAgent(value)"
            >
              <template v-slot:item="{ item }" class="tile">
                <v-tooltip bottom v-if="item.name.length > 32">
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">
                      {{ start_and_end(item.name, 32) }}
                    </span>
                  </template>
                  <span>{{ item.name }}</span>
                </v-tooltip>
                <span v-else> {{ start_and_end(item.name, 32) }}</span>
              </template>
            </v-autocomplete>
          </v-list-item>
          <v-list-item class="pt-4 not">
            <v-autocomplete
              id="vAutoCompleteSpecialRetirement"
              v-model="localFilter.specialRetirement"
              append-icon="mdi-chevron-down"
              :items="retirementRisksFilter"
              item-text="name"
              item-value="key"
              dense
              outlined
              label="Aposentadoria especial"
              clearable
              hide-details
              placeholder="Selecione uma opção"
              @input="(value) => changeSpecialRetirement(value)"
            >
              <template v-slot:selection="{ index, item }">
                <span v-if="index === 0" style="max-height: 48px" class="primary--text">
                  {{ item.name }}
                </span>
              </template>
            </v-autocomplete>
          </v-list-item>

          <v-list-item class="pt-4 not">
            <v-autocomplete
              id="vAutoCompleteAliqContri"
              v-model="localFilter.retirementAliquot"
              :items="aliqContributionOptions"
              item-text="text"
              dense
              outlined
              return-object
              append-icon="mdi-chevron-down"
              label="Contribuição / Alíquota"
              clearable
              hide-details
              placeholder="Selecione uma opção"
              @input="(value) => changeRetirementAliquot(value)"
            >
              <template v-slot:selection="{ index, item }">
                <span v-if="index === 0" style="max-height: 48px" class="primary--text">
                  {{ item.text }}
                </span>
              </template>
            </v-autocomplete>
          </v-list-item>
          <v-list-item class="pt-4 ml-4 not" style="margin-bottom: -34px !important">
            <v-switch
              :ripple="false"
              class="pa-0 ma-0"
              id="switchId"
              v-model="localFilter.onlyExposedRisks"
              inset
              label="Risco apresentado"
              @change="(value) => changeOnlyExposedRisk(value)"
            ></v-switch>
          </v-list-item>
          <v-list-item class="pa-4 pt-8 not">
            <v-btn
              id="buttonApply"
              @click="applyFilter()"
              class="buttonApplyStyle"
              :disabled="selectedLength < 1 || boolForTags"
              :style="
                habilitarBotaoFiltro
                  ? 'width: 352px; max-height: 48px !important; min-height: 48px !important;'
                  : 'width: 352px; max-height: 48px !important; min-height: 48px !important;'
              "
            >
              Aplicar filtro
            </v-btn>
          </v-list-item>
          <v-list-item class="pb-4 not">
            <v-btn
              id="buttonClear"
              class="buttonCleanStyle primary--text"
              :disabled="selectedLength < 1"
              :style="
                habilitarBotaoLimparFiltro
                  ? 'width: 352px; max-height: 48px !important; min-height: 48px !important;'
                  : 'width: 352px; max-height: 48px !important; min-height: 48px !important;'
              "
              @click="cleanFilter()"
            >
              <span class="material-symbols-outlined mr-2"> delete </span>
              Limpar filtro</v-btn
            >
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>
  </span>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'FilterButton',
  components: {},
  props: ['localFilter', 'disabledOrNot'],

  beforeMount() {
    this.prepareListeners();
  },
  data() {
    return {
      x: 0,
      y: 0,
      switch1: false,
      show: false,
      habilitarBotaoFiltro: false,
      habilitarBotaoLimparFiltro: true,
    };
  },
  computed: {
    ...mapGetters({
      aliqContributionOptions: 'wss/getTextForFilter',
      countNociveAgentsForFilter: 'wss/getCountNociveAgentsForFilter',
      retirementRisksFilter: 'wss/getRetirementRisksFilter',
      filter: 'wss/getCountNociveAgentsForFilter',
      totalCollaborators: 'wss/getTotalCollaborators',
      selectedLength: 'wss/getBoolForFilterButton',
      boolForTags: 'wss/getBoolForTags',
      filterApplied: 'wss/getFilterApply',
    }),
    nociveAgentSelect: {
      get: function () {
        return this.filter.nociveAgent;
      },
      set: function (value) {
        this.changeNociveAgent(value);
      },
    },
    specialRetirementSelected: {
      get: function () {
        return this.filter.specialRetirement;
      },
      set: function (value) {
        this.changeSpecialRetirement(value);
      },
    },
    retirementAliquotSelected: {
      get: function () {
        return this.filter.retirementAliquot;
      },
      set: function (value) {
        this.changeRetirementAliquot(value);
      },
    },
    onlyExposedRisksSelected: {
      get: function () {
        return this.filter.onlyExposedRisks;
      },
      set: function (value) {
        this.changeOnlyExposedRisk(value);
      },
    },
  },

  methods: {
    ...mapActions('wss', [
      'specificFilter',
      'cleanSpecificFilter',
      'changeNociveAgent',
      'changeSpecialRetirement',
      'changeRetirementAliquot',
      'changeOnlyExposedRisk',
      'cleanFilterTags',
      'cleanFilterPosition',
      'setBoolForFilterButton',
    ]),
    getTotalSelectedFilters() {
      return this.selectedLength ? this.selectedLength : 0;
    },
    onClick: function (event) {
      this.menu = false;
      var container = document.getElementById('myDiv');
      var bounds = container.getBoundingClientRect();
      var x = event.clientX - bounds.left;
      var y = event.clientY - bounds.top;

      this.x = x;
      this.y = y;
      this.$nextTick(() => (this.menu = true));
    },
    async buildStyle() {
      await this.sleep(300);
      let filterContainer = document.querySelector('.v-window.v-item-group.theme--light.v-tabs-items');
      if (filterContainer) {
        if (this.totalCollaborators && this.totalCollaborators > 0 && this.totalCollaborators < 12) {
          filterContainer.style.height = '1000px';
          filterContainer.style.background = 'white';
        } else {
          filterContainer.style.height = '';
          let filter = document.querySelector('#idForReplay > div');
          if (filter) {
            filter.style.zIndex = '3';
          }
        }
      }
    },
    async sleep(ms) {
      await new Promise((r) => setTimeout(r, ms));
    },
    applyFilter() {
      this.specificFilter();
      this.show = false;
      this.buildStyle();
    },
    closeFilter() {
      let localFilterForChange = {
        nociveAgent: '',
        specialRetirement: '',
        retirementAliquot: '',
        onlyExposedRisks: false,
      };
      if (!this.filterApplied && this.selectedLength < 1) {
        this.$emit('clean', localFilterForChange);
        this.clear();
      }
      this.show = false;
      this.buildStyle();
    },
    async cleanFilter(skipLoadChips) {
      let localFilterForChange = {
        nociveAgent: '',
        specialRetirement: '',
        retirementAliquot: '',
        onlyExposedRisks: false,
      };
      this.$emit('clean', localFilterForChange);
      if (!skipLoadChips) {
        await this.clear();
      }
      this.buildStyle();
    },
    async clear() {
      await this.cleanFilterPosition('nociveAgent');
      await this.cleanFilterPosition('retirementYears');
      await this.cleanFilterPosition('specialRetirement');
      await this.cleanFilterPosition('onlyExposedRisks');
    },
    async prepareListeners(e) {
      this.$root.$on('clearCollaboratorsFilter', (skipLoadChips) => {
        this.setBoolForFilterButton(0);
        this.cleanFilter(skipLoadChips);
      });
    },
    start_and_end(str, lengthStr) {
      if (str.length > lengthStr) {
        return str.substr(0, lengthStr) + '...';
      }
      return str;
    },
  },
};
</script>

<style scoped>
::v-deep .v-btn__content {
  width: 103px !important;
}

.relative-container {
  position: relative;
}
::v-deep .secundary-default {
  box-shadow: none !important;
  height: 48px !important;
  width: 103px !important;
  top: 0px;
  border-radius: 4px !important;
  align-items: flex-start;
  padding: 12px 16px 12px 16px !important;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
  border: 1px solid primary;
  background-color: #fff !important;
  color: var(--v-primary-base);
}

::v-deep .secundary-default:disabled {
  box-shadow: none !important;
  height: 48px !important;
  width: 103px !important;
  top: 0px;
  border-radius: 4px !important;
  align-items: flex-start;
  padding: 12px 16px 12px 16px !important;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: normal !important;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
  border: 0px solid #8686a2 !important;
  background-color: #e7e7fa !important;
  color: #8686a2 !important;
}

::v-deep .secundary-default:hover {
  box-shadow: none !important;
  background-color: #e3ffee !important ;
  border: 1px solid #8686a2 !important;
}

::v-deep .secundary-default::before {
  box-shadow: none !important;
  background-color: #2a1c4f !important;
}
::v-deep .secundary-default::after {
  box-shadow: none !important;
}
::v-deep .secundary-default2 {
  box-shadow: none !important;
  height: 48px !important;
  width: 128px !important;
  top: 0px;
  border-radius: 4px !important;
  align-items: flex-start;
  padding: 12px 16px 12px 16px !important;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
  border: 1px solid primary;
  background-color: #fff !important;
  color: var(--v-primary-base);
}

::v-deep .v-menu__content {
  margin-top: 4px !important;
}

::v-deep .secundary-default2:disabled {
  box-shadow: none !important;
  height: 48px !important;
  width: 128px !important;
  top: 0px;
  border-radius: 4px !important;
  align-items: flex-start;
  padding: 12px 16px 12px 16px !important;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: normal !important;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
  border: 1px solid #8686a2 !important;
  background-color: #e7e7fa !important;
  color: #8686a2 !important;
}

::v-deep .secundary-default2:hover {
  box-shadow: none !important;
  background-color: #e3ffee !important ;
  border: 1px solid #8686a2 !important;
}

::v-deep .secundary-default2::before {
  box-shadow: none !important;
  background-color: #2a1c4f !important;
}
::v-deep .secundary-default2::after {
  box-shadow: none !important;
}

::v-deep .v-input--switch--inset:hover .v-input--switch__track.theme--light:not(.primary--text) {
  background-color: #e3ffee !important;
}
::v-deep .v-input--is-label-active.v-input--switch--inset:hover .v-input--switch__track.theme--light {
  border: 1px solid #e3ffee !important;
  color: #e3ffee !important;
}
::v-deep .v-input--is-label-active.v-input--switch--inset:hover .v-input--switch__thumb.theme--light {
  color: #e3ffee !important;
  background-color: #e3ffee !important;
}
::v-deep .v-input.v-input--is-label-active .v-input--switch__track {
  background-color: var(--v-primary-base) !important;
  opacity: 1;
}
::v-deep .v-input--switch:hover {
  box-shadow: none;
}

::v-deep .v-label.v-label--active {
  color: #52526b !important;
  font-size: 14px !important;
  font-weight: normal;
}

::v-deep .v-input.v-input--is-label-active .v-input--switch__thumb {
  background-color: #ffffff !important;
}

::v-deep .v-input .v-input--switch__track {
  background-color: white !important;
  border: 2px solid var(--v-primary-base) !important;
  opacity: 1;
}
::v-deep .v-input--switch--inset {
  background-color: white !important;
}
::v-deep .v-input--switch .v-label.theme--light {
  color: #182026 !important;
  font-weight: normal;
}
::v-deep .v-input--switch__thumb.theme--light {
  height: 12px !important;
  width: 12px !important;
  margin-top: 4px !important;
  margin-left: 3px !important;
}

/*::v-deep .v-input .v-input__control:not(.v-input--switch .v-input__control):not(:hover) {
  border: 1px solid #8686a2 !important;
}*/
::v-deep .v-input .v-input--switch__thumb {
  background-color: var(--v-primary-base) !important;
}
::v-deep .v-input--switch__track {
  background-color: white !important;
}
::v-deep .v-autocomplete:hover {
  background-color: #e3ffee !important;
  border: 1px solid #e7e7fa;
}
::v-deep .v-autocomplete.v-input--is-label-active {
  border: 1px solid #e7e7fa;
}

::v-deep .v-input--switch__thumb {
  background-color: white !important;
}
::v-deep .v-select__selections {
  font-size: 16px !important;
  width: 317px !important;
  height: 40px !important;
  display: flex;
  overflow: none !important;
}
::v-deep .v-select__selection {
  color: var(--v-primary-base) !important ;
  width: 317px !important;
  height: 40px !important;
  display: flex;
  overflow: none !important;
}
::v-deep .buttonApplyStyle {
  background-color: var(--v-primary-base) !important;
  color: white !important;
  font-weight: bold !important;
  font-size: 16px !important;
  letter-spacing: 0.01em !important;
}
::v-deep .buttonApplyStyle:hover {
  background-color: #e3ffee !important;
  color: var(--v-primary-base);
  font-weight: bold !important;
  font-size: 16px !important;
  letter-spacing: 0.01em !important;
}
::v-deep .buttonApplyStyle:disabled {
  border: 1px solid #8686a2 !important;
  background-color: #e7e7fa !important;
  color: #8686a2 !important;
  font-weight: normal !important;
  font-size: 16px !important;
  letter-spacing: 0.01em !important;
}
::v-deep .buttonApplyStyle:active {
  box-shadow: none !important;
  border: 1px solid #8686a2 !important;
  background-color: #2a1c4f !important;
  color: #ffffff !important;
  font-weight: bold !important;
  font-size: 16px !important;
  letter-spacing: 0.01em !important;
  opacity: 1 !important;
}

::v-deep .buttonCleanStyle {
  color: var(--v-primary-base);
  background-color: white !important;
  font-weight: bold !important;
  border-radius: 4px !important;
  border: 1px solid primary !important;
  font-size: 16px !important;
  letter-spacing: 0.01em !important;
}
::v-deep .buttonCleanStyle:active {
  box-shadow: none !important;
  color: #ffffff !important;
  background-color: #2a1c4f !important;
  font-weight: bold !important;
  border-radius: 4px !important;
  border: 0px solid primary !important;
  font-size: 16px !important;
  letter-spacing: 0.01em !important;
}
::v-deep .buttonCleanStyle .v-btn__content .material-symbol-outlined {
  margin-right: 16px !important;
}
::v-deep .buttonCleanStyle:hover {
  color: var(--v-primary-base);
  background-color: #e3ffee !important;
  font-weight: bold !important;
  border-radius: 4px !important;
  border: 0px solid primary !important;
  font-size: 16px !important;
}
::v-deep .buttonCleanStyle:disabled {
  color: #8686a2 !important;
  background-color: #e7e7fa !important;
  font-weight: bold !important;
  border-radius: 4px !important;
  border: 0px solid white !important;
}
::v-deep .buttonCleanStyle:disabled .material-symbol-outlined {
  color: #8686a2 !important;
}
::v-deep .buttonCleanStyle .material-symbol-outlined {
  color: var(--v-primary-base);
}
::v-deep .v-select__slot .v-input__append-inner .v-icon {
  color: var(--v-primary-base) !important ;
}

::v-deep .v-select__selections:hover {
  /* color: primary !important ; */
  width: 317px !important;
  height: 40px !important;
  display: flex;
  overflow: none !important;
}
::v-deep #vAutoCompleteAgNoc {
  overflow: hidden !important;
  color: var(--v-primary-base) !important ;
  font-weight: 400;
}

::v-deep #vAutoCompleteSpecialRetirement {
  font-weight: 400;
}

::v-deep #vAutoCompleteAliqContri {
  font-weight: 400;
}

::v-deep .v-select__slot {
  color: var(--v-primary-base) !important ;
}
::v-deep .v-list-item:not(.not):hover {
  background: #e3ffee !important;
}
/* ::v-deep .v-list-item {
  color: primary !important ;
} */
::v-deep .v-autocomplete {
  max-width: 352px !important;
  max-height: 48px !important;
  border: 0px solid #8686a2;
  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;

  width: 352px;
  height: 48px;

  /* Inside auto layout */
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}
::v-deep #iconClose:hover {
  background-color: #e3ffee !important;
  border-radius: 100% !important;
}

::v-deep #closeButtonFromUp:hover {
  font-size: 14px !important;
  background-color: #e3ffee !important;

  border-radius: 100% !important;
}
::v-deep #iconClose:disabled {
  background-color: #e7e7fa !important;
  color: #8686a2 !important;
  border-radius: 100% !important;
}
::v-deep .v-btn:before {
  background-color: transparent !important;
  /* border: 1px solid green !important;  */
}
::v-deep .pa-0 ma-0 v-btn v-btn--icon v-btn--round theme--dark v-size--small {
  background-color: none !important;
  /* border: 1px solid green !important;  */
}
::v-deep .v-list-item span {
  font-size: 16px;
}
</style>
